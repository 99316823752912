import React, { useState, useEffect } from "react";
import { BsClockHistory, BsX } from "react-icons/bs";

const TextManipulator = () => {
  const [inputText, setInputText] = useState("");
  const [history, setHistory] = useState([]);
  const [showHistorySidebar, setShowHistorySidebar] = useState(false);

  useEffect(() => {
    // Load last saved text from localStorage on component mount
    const savedText = localStorage.getItem("lastText");
    if (savedText) {
      setInputText(savedText);
    }

    // Load recent history from localStorage
    const savedHistory = localStorage.getItem("history");
    if (savedHistory) {
      setHistory(JSON.parse(savedHistory));
    }
  }, []);

  const saveInputTextToLocalStorage = (text) => {
    localStorage.setItem("lastText", text);
  };

  const saveHistoryToLocalStorage = (updatedHistory) => {
    localStorage.setItem("history", JSON.stringify(updatedHistory));
  };

  const handleInputChange = (e) => {
    const text = e.target.value;
    setInputText(text);
    saveInputTextToLocalStorage(text);
  };

  const addToHistory = (text) => {
    const updatedHistory = [text, ...history.slice(0, 19)];
    setHistory(updatedHistory);
    saveHistoryToLocalStorage(updatedHistory);
  };

  const loadFromHistory = (text) => {
    setInputText(text);
    setShowHistorySidebar(false); // Hide sidebar after loading history item
  };

  const removeFromHistory = (index) => {
    const updatedHistory = [...history];
    updatedHistory.splice(index, 1);
    setHistory(updatedHistory);
    saveHistoryToLocalStorage(updatedHistory);
  };

  const clearText = () => {
    setInputText("");
    saveInputTextToLocalStorage("");
  };

  const toggleHistorySidebar = () => {
    setShowHistorySidebar(!showHistorySidebar);
  };

  const handleTextTransform = (transformFunction) => {
    const textarea = document.getElementById("inputArea");
    const start = textarea.selectionStart;
    const end = textarea.selectionEnd;

    if (start !== end) {
      const selectedText = inputText.substring(start, end);
      const transformedText = transformFunction(selectedText);
      const newText =
        inputText.substring(0, start) +
        transformedText +
        inputText.substring(end);
      setInputText(newText);
      saveInputTextToLocalStorage(newText);
      addToHistory(newText);
    } else {
      const transformedText = transformFunction(inputText);
      setInputText(transformedText);
      saveInputTextToLocalStorage(transformedText);
      addToHistory(transformedText);
    }
  };

  const sentenceCase = () => {
    handleTextTransform((text) =>
      text
        .toLowerCase()
        .replace(/(^|\. *)([a-z])/g, (match) => match.toUpperCase())
    );
  };

  const lowerCase = () => {
    handleTextTransform((text) => text.toLowerCase());
  };

  const upperCase = () => {
    handleTextTransform((text) => text.toUpperCase());
  };

  const capitalizeWords = () => {
    handleTextTransform((text) =>
      text
        .toLowerCase()
        .replace(/(^|\s)([a-z])/g, (match) => match.toUpperCase())
    );
  };

  const processKeywords = () => {
    handleTextTransform((text) =>
      text
        .trim()
        .split("\n")
        .map((keyword) => keyword.trim())
        .join(", ")
    );
  };

  const removeMultipleSpaces = () => {
    handleTextTransform((text) => text.replace(/ +/g, " "));
  };

  const beautifyHashtags = () => {
    handleTextTransform((text) =>
      text.replace(/\n/g, " ").replace(/(\#\w+)/g, "$1 ")
    );
  };

  const trimSpaces = () => {
    handleTextTransform((text) => text.trim());
  };

  const handleCut = (e) => {
    // Handle Ctrl + X cut event
    const cutText = window.getSelection().toString();
    if (cutText && cutText.trim() !== "") {
      addToHistory(cutText);
    }
  };

  return (
    <div className="main-area">
      <div className="features-area">
        <div className="container">
          <div className="row">
            <div id="inputAreaContainer">
              <textarea
                id="inputArea"
                value={inputText}
                onChange={handleInputChange}
                onCut={handleCut} // Handle cut event
                placeholder="Enter your text"
              />
            </div>

            <div className="button-container">
              <div className="btn-border">
                <button onClick={sentenceCase}>
                  <span>.</span>Sentence
                </button>
                <button onClick={lowerCase}>
                  <span>.</span>Lower
                </button>
                <button onClick={upperCase}>
                  <span>.</span>Upper
                </button>
                <button onClick={capitalizeWords}>
                  <span>.</span>Capitalize
                </button>
                <button onClick={processKeywords}>
                  <span>.</span>Keywords
                </button>
                <button onClick={trimSpaces}>
                  <span>.</span>Trim(OutText)
                </button>
                <button onClick={removeMultipleSpaces}>
                  <span>.</span>Trim(InsideText)
                </button>
                <button onClick={beautifyHashtags}>
                  <span>.</span>Inline Text
                </button>
              </div>
            </div>
          </div>
          <div className="action-buttons">
            <button onClick={toggleHistorySidebar} className="history-toggle">
              {showHistorySidebar ? (
                <BsX /> // Close icon
              ) : (
                <BsClockHistory /> // History icon
              )}
            </button>
          </div>
        </div>
      </div>

      {showHistorySidebar && (
        <div className="history-sidebar">
          <h2>Recent History</h2>
          <ul>
            {history.slice(0, 20).map((text, index) => (
              <li key={index}>
                <button
                  className="HTextBTN"
                  onClick={() => loadFromHistory(text)}
                >
                  {text.length > 20 ? `${text.substring(0, 17)}...` : text}
                </button>
                <button
                  className="CloseBTN"
                  onClick={() => removeFromHistory(index)}
                >
                  {" "}
                  <BsX />
                </button>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default TextManipulator;

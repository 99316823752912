import React from "react";
import TextManipulator from "./components/TextManipulator";

function App() {
  return (
    <div className="App">
      <TextManipulator />
    </div>
  );
}

export default App;
